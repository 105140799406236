import React, { useEffect, useState } from "react";
import { FilterIcon } from "../../utils/icons";
import {
  AvatarContainer,
  Column,
  Tab,
  Divider,
  Header,
  MyTaskCard,
  TableHeader,
  TableRow,
  Tabs,
  TaskTypeCard,
  TaskTypeTab,
  Title,
  ReminderDot,
  DataColumn,
  ScrollableContainer,
  ShowMore,
  NameInitials,
} from "./styles";
import { GetMyTasksDetails } from "../../redux/constants/apiConstants";
import { toast } from "react-toastify";
import {
  fetchApi,
  getInitials,
  getStartAndEndDates,
} from "../../utils/methods";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import RecognitionModal from "./RecognitionModal";
import LazyImage from "../common/LazyImage/LazyImage";
import { ImageUrl } from "../../utils/constants";
import { useSelector } from "react-redux";

const CustomToggleV1 = styled(Dropdown.Toggle)`
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  background: none !important;
  border: none !important;

  &:hover {
    color: #00abb6;
  }

  &::after {
    display: none !important;
  }
`;

const CustomMenuV1 = styled(Dropdown.Menu)`
  background-color: red;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0px !important;
  width: 136px !important;
  height: 144px !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
`;

const CustomDropdownItemV1 = styled(Dropdown.Item)`
  font-family: "Lato" !important;
  font-size: 16px !important;
  padding: 14px 16px !important;
  border-radius: 4px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${({ active }) => (active ? "white" : "#184A61")} !important;
  background-color: ${({ active }) =>
    active ? "#00ABB6" : "transparent"} !important;

  &:hover {
    color: ${({ active }) => (active ? "white" : "white")} !important;
    background-color: ${({ active }) =>
    active ? "#00ABB6" : "#088A96"} !important;
    ${"" /* background-color: #00ABB6 !important; */}
    ${"" /* color: white !important; */}
    border-radius:4px  !important;
  }
`;

function MyTasksCard() {
  const { user } = useSelector((state) => state.profileData);
  const [myTaskData, setMyTaskData] = useState([]);
  const [timeInterval, setTimeInterval] = useState("Monthly");
  const [tabType, setTabType] = useState("overdue");
  const [taskType, setTaskType] = useState("all");
  const [showAllRows, setShowAllRows] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    fetchMyTaskData();
  }, [timeInterval, tabType]);

  useEffect(() => {
    fetchMyTaskData(true);
  }, [timeInterval]);

  const fetchMyTaskData = async (initialLoad = false) => {
    const { startDate, endDate } = getStartAndEndDates(timeInterval);
    const apiUrl = `${GetMyTasksDetails}/${tabType}/${startDate}/${endDate}`;

    try {
      const res = await fetchApi(apiUrl, "GET");
      if (res.status === "success") {
        setMyTaskData(res.data);
        if (tabType === "overdue") {
          setCount(res.data.all.length);
          if (initialLoad && res.data.all.length === 0) {
            setTabType("upcoming");
          }
        }
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  const handleSelect = (eventKey) => {
    setTimeInterval(eventKey);
  };

  const handleTabTypeClick = (type) => {
    setTabType(type);
    fetchMyTaskData();
  };

  const getFilteredData = () => {
    switch (taskType) {
    case "recognition":
      return myTaskData?.recognition || [];
    case "birthday":
      return myTaskData?.birthday || [];
    case "anniversary":
      return myTaskData?.anniversary || [];
    default:
      return myTaskData?.all || [];
    }
  };

  const renderTableRows = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [recognitionType, setRecognitionType] = useState(null);

    const filteredData = getFilteredData();

    const handleOpenModal = (item) => {
      setRecognitionType(item.recognition_type);
      setModalData(item);
      setShowModal(true);
    };

    const handleCloseModal = () => {
      setShowModal(false);
      setModalData(null);
    };

    if (filteredData.length === 0) {
      return (
        <div
          style={{
            textAlign: "center",
            marginTop: "20px",
            color: "#184A61",
            fontSize: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Lato",
          }}
        >
          No tasks found!
        </div>
      );
    }

    const rowsToShow = showAllRows ? filteredData : filteredData.slice(0, 5);

    return (
      <>
        {rowsToShow.map((item, index) => {
          const isDisabled =
            (item.recognition_type === "birthday" ||
              item.recognition_type === "anniversary") &&
            moment(item.due_date).isAfter(moment());

          return (
            <React.Fragment key={index}>
              <TableRow>
                <DataColumn>
                  <AvatarContainer isProfile={true}>
                    {item?.profile_image !== "profile-Image/default.png" ? (
                      <LazyImage
                        style={{
                          width: "48px",
                          height: "48px",
                          borderRadius: "48px",
                          border: "1px solid  #DCDCDC",
                        }}
                        onError={(e) => {
                          e.target.style.display = "none";
                        }}
                        src={`${ImageUrl}/${item?.profile_image}`}
                      />
                    ) : (
                      <NameInitials>
                        {getInitials(item?.full_name)}
                      </NameInitials>
                    )}
                  </AvatarContainer>
                  {item.full_name}
                </DataColumn>
                <DataColumn
                  isColor={tabType === "overdue" ? "#E12133" : "#184A61"}
                >
                  {item.recognition_type === "recognition"
                    ? moment(item.due_date).format("MMM")
                    : moment(item.due_date).format("DD, MMM")}
                </DataColumn>

                <DataColumn isCapital={true}>
                  <ReminderDot
                    isReminderColor={item.recognition_color}
                  ></ReminderDot>
                  {item.recognition_type === "anniversary"
                    ? "Work Anniversary"
                    : item.recognition_type}
                </DataColumn>
                <DataColumn
                  isColor={isDisabled ? "#989898" : "#184A61"}
                  onClick={!isDisabled ? () => handleOpenModal(item) : null}
                  style={{ cursor: "pointer" }}
                >
                  {item.action}
                </DataColumn>
              </TableRow>
              <Divider isMargin={"8px 0px"}></Divider>
            </React.Fragment>
          );
        })}

        {showModal && (
          <RecognitionModal
            show={showModal}
            modalData={modalData}
            onClose={handleCloseModal}
            recognitionType={recognitionType}
            loggedInUser={user}
          />
        )}
      </>
    );
  };

  const filteredData = getFilteredData();

  return (
    <div>
      <MyTaskCard>
        <Header>
          <AvatarContainer isProfile={true}>
            <LazyImage
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "40px",
                border: "1px solid  #dcdcdc",
              }}
              onError={() => window.console.error("Image failed to load")}
              src={`${ImageUrl}/${user?.profile_image}`}
            />
            <Title>My Tasks</Title>
          </AvatarContainer>

          <Tabs>
            <Tab
              onClick={() => handleTabTypeClick("upcoming")}
              active={tabType === "upcoming"}
            >
              Upcoming
            </Tab>
            <Tab
              onClick={() => handleTabTypeClick("overdue")}
              active={tabType === "overdue"}
            >
              {count === 0 ? "Overdue" : `Overdue (${count})`}
            </Tab>
            <Tab
              onClick={() => handleTabTypeClick("completed")}
              active={tabType === "completed"}
            >
              Completed
            </Tab>
          </Tabs>

          <Dropdown onSelect={handleSelect} style={{ cursor: "pointer" }}>
            <CustomToggleV1 id="dropdown-custom-components">
              {FilterIcon()}
            </CustomToggleV1>

            <CustomMenuV1 align="end">
              {["Monthly", "Quarterly", "Yearly"].map((interval) => (
                <CustomDropdownItemV1
                  key={interval}
                  eventKey={interval}
                  active={timeInterval === interval}
                >
                  {interval}
                </CustomDropdownItemV1>
              ))}
            </CustomMenuV1>
          </Dropdown>
        </Header>
        <TaskTypeCard>
          <TaskTypeTab
            onClick={() => setTaskType("all")}
            active={taskType === "all"}
          >
            All
          </TaskTypeTab>
          <TaskTypeTab
            onClick={() => setTaskType("recognition")}
            active={taskType === "recognition"}
          >
            Recognition
          </TaskTypeTab>
          <TaskTypeTab
            onClick={() => setTaskType("anniversary")}
            active={taskType === "anniversary"}
          >
            Work Anniversaries
          </TaskTypeTab>
          <TaskTypeTab
            onClick={() => setTaskType("birthday")}
            active={taskType === "birthday"}
          >
            {"Birthday's"}
          </TaskTypeTab>
        </TaskTypeCard>

        <Divider isMargin={"10px 0px"}></Divider>
        <TableHeader>
          <Column>Employee</Column>
          <Column>{tabType === "overdue" ? "Due Date" : "Date"}</Column>
          <Column>Reminder</Column>
          <Column>
            {tabType === "completed" ? "Completed Action" : "Action"}
          </Column>
        </TableHeader>
        <ScrollableContainer
          showAllRows={showAllRows}
          height="380px"
        >
          {renderTableRows()}
          {filteredData.length > 5 && !showAllRows && (
            <ShowMore onClick={() => setShowAllRows(true)}>Show More</ShowMore>
          )}
        </ScrollableContainer>

        {/* {filteredData.length > 5 && !showAllRows && (
          <ShowMore
            onClick={() => setShowAllRows(true)}
          >
            Show More
          </ShowMore>
        )} */}
      </MyTaskCard>
    </div>
  );
}

export default MyTasksCard;
